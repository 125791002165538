import {User} from "@src/Models";

export function isAuthenticated(): boolean {
	if(localStorage.getItem("user") == null) return false;
	let user: User = JSON.parse(localStorage.getItem("user"));
	let jwt: {nbf: number, exp: number} = JSON.parse(atob(user.accessToken.split(".")[1]));
	let now: number = Math.ceil(Date.now() / 1000);
	if(now >= jwt.exp) {
		localStorage.removeItem('user');
		return false;
	}
	return true;
}
export function login(user: User): void {
	localStorage.setItem("user", JSON.stringify(user));
}