import * as Axios from 'axios';
import axios from 'axios';
import * as appParams from '@src/params';
import {User, Account, Currency, Transaction, TransactionPattern,
	TransactionLinePattern, AccountTag, AccountBalance,
	AccountLine, StatementLine, BalanceCheckSchedule} from '@src/Models';

const client: Axios.AxiosInstance = axios.create({
	baseURL: appParams.apiBase
});

client.interceptors.request.use(function (config) {
	if(localStorage.getItem("user") !== null) {
		let user: User = JSON.parse(localStorage.getItem("user"));
		let jwt: {nbf: number, exp: number} = JSON.parse(atob(user.accessToken.split(".")[1]));
		let now: number = Math.ceil(Date.now() / 1000);
		if(now >= jwt.exp)
			localStorage.removeItem('user');
		else if((jwt.nbf + (jwt.exp - jwt.nbf) * 0.75) <= now && config.url != "/auth/refresh-token")
			getRefreshToken().then((user: User) => {
				localStorage.setItem('user', JSON.stringify(user));
			}).catch(() => {
				console.log("Timeout logout");
				localStorage.removeItem('user');
			});
		if(user !== undefined)
			config.headers = {...config.headers, Authorization: "Bearer " + user.accessToken}
	}
	return config;
})

export function getAuthUrl(clientId: string): Promise<string> {
	return client.get("/auth/url", {
		params: {
			client: clientId
		}
	}).then((response: Axios.AxiosResponse<any>) => {
		return response.data.url;
	});
}

export function sendAuthCode(params: Object): Promise<User> {
	return client.get("/auth/authenticate", {
		params: params
	}).then((response: Axios.AxiosResponse<User>) => {
		return response.data;
	});
}

export function getAccounts(): Promise<Account[]> {
	return client.get("/accounts/list", {
		params: {
			expand: 'tags'
		}
	}).then((response: Axios.AxiosResponse<Account[]>) => {
		return response.data;
	});
}

export function getCurrencies(): Promise<Currency[]> {
	return client.get("/currencies/list")
		.then((response: Axios.AxiosResponse<Currency[]>) => {
		return response.data;
	});
}

export function getAccountTags(): Promise<AccountTag[]> {
	return client.get("/accounts/tags")
		.then((response: Axios.AxiosResponse<AccountTag[]>) => {
		return response.data;
	});
}

function getRefreshToken(): Promise<User> {
	return client.get("/auth/refresh-token").then((response: Axios.AxiosResponse<User>) =>	{
		return response.data;
	});
}

export function getTransactions(filter: any): Promise<Transaction[]> {
	return client.get("/transactions/list", {
		params: {
			expand: 'lines',
			...filter
		},
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			return data.map(item => {
				return {
					...item,
					date: new Date(item.date)
				};
			});}]
	}).then((response: Axios.AxiosResponse<Transaction[]>) => {
		return response.data;
	});
}

export function postTransaction(transaction: Transaction): Promise<Transaction> {
	return client.post("/transactions/save?expand=lines", transaction, {
		transformRequest: [(data: Transaction) => JSON.stringify({...data,
			date: (data.date as Date).toISOString()
		})]
	}).then((response: Axios.AxiosResponse<Transaction>) => {
		return response.data;
	});
}

export function deleteTransaction(transaction: Transaction): Promise<void> {
	return client.delete("/transactions/delete", {
		params: {
			id: transaction.id
		}
	});
}
export function getPurposes(accountId: string): Promise<string[]> {
	return client.get("/accounts/purposes", {
		params: {
			accountId: accountId
		}
	}).then((response: Axios.AxiosResponse<{purpose: string}[]>) => {
		return response.data.map(record => record.purpose);
	});
}

export function getTransactionPatterns(): Promise<TransactionPattern[]> {
	return client.get("/preferences/transaction-patterns", {
		params: {
			expand: 'lines'
		}
	}).then((response: Axios.AxiosResponse<TransactionPattern[]>) => {
		return response.data;
	});
}

export function getTransactionLinePatterns(): Promise<TransactionLinePattern[]> {
	return client.get("/preferences/line-patterns", {
		params: {
			expand: 'tags'
		}
	}).then((response: Axios.AxiosResponse<TransactionLinePattern[]>) => {
		return response.data;
	});
}

export function getProfitAndLoss(from: Date, to: Date): Promise<AccountBalance[]> {
	return client.get("/transactions/profit-loss", {
		params: {
			from: from.toISOString(),
			to: to.toISOString(),
		}
	}).then((response: Axios.AxiosResponse<AccountBalance[]>) => {
		return response.data;
	});
}

export function getBalanceSheet(date: Date): Promise<AccountBalance[]> {
	return client.get("/transactions/balance-sheet", {
		params: {
			date: date.toISOString(),
		}
	}).then((response: Axios.AxiosResponse<AccountBalance[]>) => {
		return response.data;
	});
}

export function getAccountDetails(accountId: string, purpose: string, from: Date, to: Date): Promise<AccountLine[]> {
	return client.get("/accounts/account-lines", {
		params: {
			accountId: accountId,
			purpose: purpose,
			from: from.toISOString(),
			to: to.toISOString(),
		},
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			return data.map(item => {
				return {
					...item,
					date: new Date(item.date)
				};
			});}]
	}).then((response: Axios.AxiosResponse<AccountLine[]>) => {
		return response.data;
	});
}

export function getBookParams(): Promise<any> {
	return client.get("/preferences/book-params").then((response: Axios.AxiosResponse<any>) =>	{
		return response.data;
	});
}

export function getUnreconciledLines(): Promise<StatementLine[]> {
	return client.get("/reconcilement/unreconciled-statement-lines", {
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			return data.map(item => {
				return {
					...item,
					date: new Date(item.date)
				};
			});}]
	}).then((response: Axios.AxiosResponse<StatementLine[]>) => {
		return response.data;
	});
}

export function getCheckSchedules(): Promise<BalanceCheckSchedule[]> {
	return client.get("/balance-checks/check-schedules", {
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			return data.map(item => {
				return {
					...item,
					date: new Date(item.date)
				};
			});}]
	}).then((response: Axios.AxiosResponse<BalanceCheckSchedule[]>) => {
		return response.data;
	});
}

export function reportBalance(accountId: string, amount: number) {
	return client.get("/reconcilement/report-balance", {
		params: {
			accountId: accountId,
			date: new Date().toISOString(),
			amount: amount,
		}
	});
}

export function saveAccountTag(accountTag: AccountTag): Promise<AccountTag> {
	if(accountTag.id == null)
		return client.put("/accounts/tags", accountTag).then(response => response.data);
	else
		return client.patch("/accounts/tags?id=" + accountTag.id, accountTag).then(response => response.data);
}

export function deleteAccountTag(id: number) {
	return client.delete("/accounts/tags?id=" + id);
}

export function saveLinePattern(linePattern: TransactionLinePattern): Promise<TransactionLinePattern> {
	if(linePattern.id == null)
		return client.put("/preferences/line-patterns", linePattern).then(response => response.data);
	else
		return client.patch("/preferences/line-patterns?id=" + linePattern.id, linePattern).then(response => response.data);
}

export function deleteLinePattern(id: number) {
	return client.delete("/preferences/line-patterns?id=" + id);
}

export function setLinePatternTags(id: number, tags: number[]) {
	return client.patch("/preferences/line-patterns/" + id + "/tags", tags);
}

export function saveTransactionPattern(transactionPattern: TransactionPattern): Promise<TransactionPattern> {
	if(transactionPattern.id == null)
		return client.put("/preferences/transaction-patterns", transactionPattern).then(response => response.data);
	else
		return client.patch("/preferences/transaction-patterns?id=" + transactionPattern.id, transactionPattern).then(response => response.data);
}

export function deleteTransactionPattern(id: number) {
	return client.delete("/preferences/transaction-patterns?id=" + id);
}

export function setTransactionPatternLines(id: number, lineIds: number[]) {
	return client.patch("/preferences/transaction-patterns/" + id + "/lines", lineIds);
}

export function saveAccount(account: Account): Promise<Account> {
	return client.put("/accounts", account).then(response => response.data);
}

export function updateAccount(account: Account): Promise<Account> {
	return client.patch("/accounts?id=" + account.id, account).then(response => response.data);
}

export function deleteAccount(id: string) {
	return client.delete("/accounts?id=" + id);
}

export function saveCurrency(currency: Currency): Promise<Currency> {
	return client.put("/currencies", currency).then(response => response.data);
}

export function updateCurrency(currency: Currency): Promise<Currency> {
	return client.patch("/currencies?id=" + currency.code, currency).then(response => response.data);
}

export function deleteCurrency(code: string) {
	return client.delete("/currencies?id=" + code);
}

export function setAccountTags(accountId: string, tagIds: number[]) {
	return client.patch("/accounts/" + accountId + "/tags", tagIds);
}

export function getIcons(): Promise<string[]> {
	return client.get("/preferences/icon-list").then(response => response.data);
}
