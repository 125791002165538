import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {AccountTag, Account} from "@src/Models";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";
import {updateAccount} from "@src/Actions";

export function AccountTagsManager() {
	const accountTags = useSelector<any, any>(state => state.appState.accountTags);
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const [selectedTags, setSelectedTags] = React.useState<number[]>([]);
	const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
	const dispatch = useDispatch();
	function applySelection() {
		Promise.all(selectedAccounts.map(account => apiClient.setAccountTags(account, selectedTags)))
		.then(() => {
			selectedAccounts.forEach(accountId => {
				let account: Account = accounts[accountId];
				account.tags = selectedTags;
				dispatch(updateAccount(account))
			});
		})
		.then(() => alert("OK"))
		.catch(() => alert("NG"));
	}
	async function addSelection() {
		try {
			for(let accountId of selectedAccounts) {
				let account: Account = accounts[accountId];
				let newTags = [...new Set([...account.tags, ...selectedTags])];
				await apiClient.setAccountTags(account.id, newTags);
				account.tags = newTags;
				dispatch(updateAccount(account));
			}
			alert("OK");
		} catch(e) {
			alert("NG");
		}
	}
	function clear() {
		setSelectedTags([]);
		setSelectedAccounts([]);
	}
	return <div className="account-tags-manager">
	<ul className="tags-selector">
	{Object.values(accountTags).map((tag: AccountTag) => <li key={tag.id}><input type="checkbox" checked={selectedTags.includes(tag.id)} onChange={e => e.target.checked ? setSelectedTags([...selectedTags, tag.id]) : setSelectedTags(selectedTags.filter(e => e != tag.id))} />{tag.tag}</li>)}
	</ul>
	<ul className="accounts-selector">
	{Object.values(accounts).map((account: Account) => <li key={account.id}><input type="checkbox" checked={selectedAccounts.includes(account.id)} onChange={e => e.target.checked ? setSelectedAccounts([...selectedAccounts, account.id]) : setSelectedAccounts(selectedAccounts.filter(e => e != account.id))} />
		{account.id + " - " + account.name}<br />{account.tags.map(tagId => accountTags[tagId].tag).join(", ")}</li>)}
	</ul>
	<Button variant="primary" onClick={applySelection}>タグを設定</Button>
	<Button variant="primary" onClick={addSelection}>タグを追加</Button>
	<Button variant="primary" onClick={clear}>リセット</Button>
	</div>;
}
