import {ensureTransactionRangeThunk, prepareTransactionEditorThunk,
profitLossThunk, balanceSheetThunk, accountLinesThunk, loadMasterDataThunk, prepareTransactionPatternsThunk} from "@src/Actions";
import {processLogin} from "@src/Login";

export default {
	topPage: "/",
	loginPage: {
		path: "/login",
		thunk: processLogin
	},
	calendar: {
		path: "/:year([0-9]{4})/:month([0-9]{2})",
		thunk: ensureTransactionRangeThunk,
	},
	dayDetails: {
		path: "/:year([0-9]{4})/:month([0-9]{2})/:day([0-9]{2})",
		thunk: ensureTransactionRangeThunk,
	},
	transactionEditor: {
		path: "/transaction/:id",
		thunk: prepareTransactionEditorThunk,
	},
	profitLoss: {
		path: "/profit-loss",
		thunk: profitLossThunk,
	},
	balanceSheet: {
		path: "/balance-sheet",
		thunk: balanceSheetThunk,
	},
	accountLines: {
		path: "/account-lines",
		thunk: accountLinesThunk,
	},
	tags: {
		path: "/tags",
		thunk: loadMasterDataThunk,
	},
	currencies: {
		path: "/currencies",
		thunk: loadMasterDataThunk,
	},
	accounts: {
		path: "/accounts",
		thunk: loadMasterDataThunk,
	},
	accountTags: {
		path: "/accounts-tags",
		thunk: loadMasterDataThunk,
	},
	linePatterns: {
		path: "/line-patterns",
		thunk: prepareTransactionPatternsThunk,
	},
	transactionPatterns: {
		path: "/transaction-patterns",
		thunk: prepareTransactionPatternsThunk,
	},
};