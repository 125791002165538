import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {Account} from "@src/Models";
import {Overlay} from "@src/Utils";
import {updateAccount, deleteAccount} from "@src/Actions";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";

function AccountEditor(props: {
	id: string;
}) {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [account, setAccount] = React.useState<Account>(null);
	React.useEffect(() => setAccount(props.id == null ? {
		name: "新規",
		needsPurpose: false,
		accountType: 0,
		tags: []
	} : accounts[props.id]), [showEditor]);
	const dispatch = useDispatch();
	function save() {
		if(props.id == null)
			apiClient.saveAccount(account).then(account => { dispatch(updateAccount(account)); setShowEditor(false); })
		else
			apiClient.updateAccount(account).then(account => { dispatch(updateAccount(account)); setShowEditor(false); })
	}
	function deleteRecord() {
		apiClient.deleteAccount(account.id).then(() => { dispatch(deleteAccount(account.id)); setShowEditor(false); })
	}
	if(account == null) return null;
	return <li onClick={() => setShowEditor(true)}>
		{account.id != null ? account.id + ": " + account.name : account.name}
		{!showEditor || <Overlay onDismiss={() => setShowEditor(false)}>
		<div className="account-editor">
		<div className="field"><label>コード</label>
		<input value={account.id} onChange={e => setAccount({...account, id: e.target.value})} readOnly={props.id != null} />
		</div>
		<div className="field"><label>名称</label>
		<input value={account.name} onChange={e => setAccount({...account, name: e.target.value})} />
		</div>
		<div className="field"><label>通貨</label>
		<select value={account.currency} onChange={e => setAccount({...account, currency: e.target.value})}>
		{Object.keys(currencies).map(code => <option key={code} value={code}>{currencies[code].name + " (" + code + ")"}</option>)}
		</select>
		</div>
		<div className="field"><label>カテゴリー</label>
		<select value={account.accountType} onChange={e => setAccount({...account, accountType: Number.parseInt(e.target.value)})}>
		<option value="0">資産</option>
		<option value="1">負債</option>
		<option value="2">純資産</option>
		<option value="3">支出</option>
		<option value="4">収入</option>
		</select>
		</div>
		<div className="field"><label>詳細が必須</label>
		<input type="checkbox" checked={account.needsPurpose} onChange={e => setAccount({...account, needsPurpose: e.target.checked})} />
		</div>
		<Button variant="primary" onClick={save}>保存</Button>
		<Button variant="primary" onClick={() => setShowEditor(false)}>キャンセル</Button>
		{props.id == null || <Button variant="primary" onClick={deleteRecord}>削除</Button>}
		</div>
		</Overlay>}
	</li>;
}

export function AccountsManager() {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	return <>
	<ul className="accounts-manager">
	{Object.values(accounts).map((account: Account) => <AccountEditor key={account.id} id={account.id} />)}
	<AccountEditor key="new" id={null} />
	</ul>
	</>;
}
