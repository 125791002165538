import * as React from "react";
import {Login} from "@src/Login";
import {AppMenu} from "@src/AppMenu";
import "@src/app.scss";
import {User} from "@src/Models";
import {useDispatch, useSelector} from "react-redux";
import {DayDetails, DaySelector} from "@src/DayDetails";
import {TransactionEditor} from "@src/TransactionEditor";
import {MonthSelector, Calendar} from "@src/Calendar";
import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import {Provider} from "react-redux";
import {appReducer} from "@src/Reducers";
import { connectRoutes } from 'redux-first-router';
import routes from "@src/Routes";
import * as queryString from "query-string";
import * as dateFns from "date-fns";
import {ProfitLoss, BalanceSheet} from "@src/AccountBalancesList";
import {AccountLines} from "@src/AccountLines";
import {BalanceChecker} from "@src/BalanceChecker";
import {TagsManager} from "@src/TagsManager";
import {CurrenciesManager} from "@src/CurrenciesManager";
import {AccountsManager} from "@src/AccountsManager";
import {AccountTagsManager} from "@src/AccountTagsManager";
import {LinePatternsManager} from "@src/LinePatternsManager";
import {TransactionPatternsManager} from "@src/TransactionPatternsManager";

class ErrorBoundary extends React.Component<any, {hasError: boolean}> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}
	static getDerivedStateFromError(error: any) {
		return { hasError: true };
	}
	render() {
		if(this.state.hasError)
			return <>コケちゃった&#x1f605;<br />ホームに戻ってね</>;
		return this.props.children; 
	}
}

function RouterOutput() {
	const user = useSelector<any, User>(state => state.appState.user);
	const location = useSelector<any, any>(state => state.location);
	const dispatch = useDispatch();
	if(user == null) {
		if(location.type == "loginPage") return <Login />;
		dispatch({type: "loginPage"});
		return null;
	}
	let page = null;
	if(location.type == "topPage")
		dispatch({type: "calendar", payload: {year: dateFns.format(new Date(), "yyyy"), month: dateFns.format(new Date(), "MM")}});
	if(location.type == "calendar") {
		const header: React.ReactElement[] = [<>S</>, <>M</>, <>T</>, <>W</>, <>T</>, <>F</>, <>S</>];
		page = <div className="calendar-wrapper">
		<div className="header"><MonthSelector /></div>
		<Calendar header={(weekday) => header[weekday]} />
		</div>;
	}
	if(location.type == "dayDetails") {
		page = <div className="day-wrapper">
		<div className="header"><DaySelector /></div>
		<DayDetails />
		</div>;
	}
	if(location.type == "transactionEditor") page = <TransactionEditor />;
	if(location.type == "profitLoss") page = <ProfitLoss />;
	if(location.type == "balanceSheet") page = <BalanceSheet />;
	if(location.type == "accountLines") page = <AccountLines />;
	if(location.type == "tags") page = <TagsManager />;
	if(location.type == "currencies") page = <CurrenciesManager />;
	if(location.type == "accounts") page = <AccountsManager />;
	if(location.type == "accountTags") page = <AccountTagsManager />;
	if(location.type == "linePatterns") page = <LinePatternsManager />;
	if(location.type == "transactionPatterns") page = <TransactionPatternsManager />;
	return <>
		<main><ErrorBoundary>{page}<BalanceChecker /></ErrorBoundary></main>
		<AppMenu />
	</>;
}

export function App() {
	const { reducer, middleware, enhancer } = connectRoutes(routes, {querySerializer: queryString});
	let composeEnhancers = compose;
	if((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ != undefined)
		composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 });
	const middlewares = applyMiddleware(middleware)
	const enhancers = composeEnhancers(enhancer, middlewares);
	return <Provider store={createStore(combineReducers({appState: appReducer, location: reducer}), enhancers)}>
		<RouterOutput />
		</Provider>
}
