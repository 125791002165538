import {combineReducers} from "redux";
import {Transaction, User, AccountBalance, AccountLine, StatementLine} from "@src/Models";
import * as authService from "@src/services/AuthService";
import routes from "@src/Routes";

function currenciesReducer(state: any = {}, action: any): any {
	if(action.type == "loadMasterData") 
		return action.currencies;
	if(action.type == "updateCurrency")
		return {...state, [action.data.code]: action.data};
	if(action.type == "deleteCurrency") {
		delete state[action.code];
		return {...state};
	}
	return state;
}
function accountTagsReducer(state: any = {}, action: any): any {
	if(action.type == "loadMasterData") 
		return action.accountTags;
	if(action.type == "updateAccountTag")
		return {...state, [action.data.id]: action.data};
	if(action.type == "deleteAccountTag") {
		delete state[action.id];
		return {...state};
	}
	return state;
}
function accountsReducer(state: any = {}, action: any): any {
	if(action.type == "loadMasterData") 
		return action.accounts;
	if(action.type == "updateAccount")
		return {...state, [action.data.id]: action.data};
	if(action.type == "deleteAccount") {
		delete state[action.id];
		return {...state};
	}
	return state;
}

function transactionsReducer(state = {}, action: any): any {
	if(action.type == "loadTransactions") 
		return action.transactions;
	if(action.type == "addTransaction") 
		return {...state, [action.transaction.id]: action.transaction};
	return state;
}

function userReducer(state: User = null, action: any): User {
	if(state === null && authService.isAuthenticated())
		return JSON.parse(localStorage.getItem("user"));
	if(action.type == "loginUser") {
		authService.login(action.user);
		return action.user;
	}
	return state;
}

function dataFetchingReducer(state = false, action: any): boolean {
	if(Object.keys(routes).indexOf(action.type) > -1 &&
		typeof ((routes as any)[action.type]) != "string" &&
		((routes as any)[action.type].thunk != undefined))
		return true;
	if(action.type == "fetchingComplete") return false;
	return state;
}

function masterDataDateReducer(state = null, action: any): Date {
	if(action.type == "loadMasterData") return action.masterDataDate;
	return state;
}

function transactionEditorReducer(state = null, action: any): Transaction {
	if(action.type == "loadTransactionEditor") return action.transaction;
	return state;
}

function profitLossReducer(state: AccountBalance[] = null, action: any): AccountBalance[] {
	if(action.type == "loadProfitLossData") return action.data;
	return state;
}

function balanceSheetReducer(state: AccountBalance[] = null, action: any): AccountBalance[] {
	if(action.type == "loadBalanceSheetData") return action.data;
	return state;
}

function transactionPatternsReducer(state: any = {}, action: any): any {
	if(action.type == "loadTransactionPatterns") return action.data;
	if(action.type == "updateTransactionPattern")
		return {...state, [action.data.id]: action.data};
	if(action.type == "deleteTransactionPattern") {
		delete state[action.id];
		return {...state};
	}
	return state;
}

function linePatternsReducer(state: any = {}, action: any): any {
	if(action.type == "loadLinePatterns") return action.data;
	if(action.type == "updateLinePattern")
		return {...state, [action.data.id]: action.data};
	if(action.type == "deleteLinePattern") {
		delete state[action.id];
		return {...state};
	}
	return state;
}

function accountLinesReducer(state = [], action: any): AccountLine[] {
	if(action.type == "loadAccountLinesData") return action.data;
	return state;
}

function bookParamsReducer(state = {}, action: any): any {
	if(action.type == "loadMasterData") return action.bookParams;
	return state;
}

function unreconciledLinesReducer(state = [], action: any): StatementLine[] {
	if(action.type == "loadUnreconciledLines") return action.data;
	return state;
}

function iconsReducer(state: string[] = [], action: any): string[] {
	if(action.type == "loadIcons") return action.data;
	return state;
}

export const appReducer = combineReducers({
	currencies: currenciesReducer,
	accountTags: accountTagsReducer,
	accounts: accountsReducer,
	transactions: transactionsReducer,
	user: userReducer,
	dataFetching: dataFetchingReducer,
	masterDataDate: masterDataDateReducer,
	transactionEditor: transactionEditorReducer,
	profitLoss: profitLossReducer,
	balanceSheet: balanceSheetReducer,
	transactionPatterns: transactionPatternsReducer,
	linePatterns: linePatternsReducer,
	accountLines: accountLinesReducer,
	bookParams: bookParamsReducer,
	unreconciledLines: unreconciledLinesReducer,
	icons: iconsReducer,
})
