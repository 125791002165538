import * as React from "react";
import * as dateFns from 'date-fns';
import {Transaction, Account, AccountType, StatementLine} from "@src/Models";
import {v1} from "uuid";
import {useSelector, useDispatch} from "react-redux";
import Link from "redux-first-router-link";
import {calendar} from "@src/Actions";
import Button from "react-bootstrap/Button";
import {useSwipeable} from 'react-swipeable';
import {AmountDisplay} from "@src/Utils";

const daysInWeek: number = 7;
Calendar.defaultProps = {
	startDay: 0
}
function DayCell(props:{
	day: Date,
	transactions: Transaction[],
	unreconciledLines: StatementLine[],
}) {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const bookParams = useSelector<any, any>(state => state.appState.bookParams);
	const today = dateFns.format(new Date(), "yyyyMMdd");
	let hasUnbalancedLines: boolean = false;
	let totals = props.transactions.reduce((total, transaction) => {
		return transaction.lines.reduce((total, line) => {
			let account: Account = accounts[line.account];
			if(account.accountType == AccountType.expense)
				return {...total, expenses: total.expenses + line.bookAmount};
			if(account.accountType == AccountType.revenue)
				return {...total, revenues: total.revenues + line.bookAmount};
			return total;
		}, total);
	}, {expenses: 0, revenues: 0});
	props.transactions.forEach(transaction => transaction.lines.forEach(line => {
		if(line.account == bookParams.accountForUnbalanced) hasUnbalancedLines = true;
	}))
	if(totals == null) totals = {expenses: 0, revenues: 0};
	return <Link to={dateFns.format(props.day, "/yyyy/MM/dd")}>
	<span className={"day-number" + (today == dateFns.format(props.day, "yyyyMMdd") ? " today" : "")}>{dateFns.format(props.day, "d")}</span>
	<span className="expenses">{totals.expenses != 0 ? <AmountDisplay amount={totals.expenses} currencyCode={null} /> : ""}</span>
	<span className="revenues">{totals.revenues != 0 ? <AmountDisplay amount={totals.revenues} currencyCode={null} /> : ""}</span>
	<div className="dayCellIcons">
	{(props.unreconciledLines.length == 0 && !hasUnbalancedLines) || <img src="/api/ico/attention.svg" alt="attention" />}
	</div>
	</Link>;
}
export function MonthSelector() {
	const location = useSelector<any, any>(state => state.location);
	const dispatch = useDispatch();
	const {year, month} = location.payload;
	const startOfMonth = new Date(year, month - 1, 1, 0, 0, 0);
	function move(step: number) {
		dispatch(calendar(dateFns.addMonths(startOfMonth, step)));
	}
	function moveToday() {
		dispatch(calendar(new Date()));
	}
	return <>
		<Button variant="light" onClick={() => move(-1)}>&lt;</Button>
		<span className="month">{dateFns.format(startOfMonth, "yyyy/MM")}
		<img src="/api/ico/calendar.svg" className="today" alt="Today" onClick={moveToday} /></span>
		<Button variant="light" onClick={() => move(+1)}>&gt;</Button>
		</>;
}
export function Calendar(props: {
	startDay?: number;
	header?: (day: number) => React.ReactElement;
}) {
	const location = useSelector<any, any>(state => state.location);
	const {year, month} = location.payload;
	const startOfMonth = new Date(year, month - 1, 1, 0, 0, 0);
	const dispatch = useDispatch();
	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => {dispatch(calendar(dateFns.addMonths(startOfMonth, 1)))},
		onSwipedRight: () => {dispatch(calendar(dateFns.addMonths(startOfMonth, -1)))},
	});
	
	let endOfMonth = dateFns.endOfMonth(startOfMonth);
	let headers: React.ReactElement[] = [];
	let cells: React.ReactElement[] = [];
	let startWeekday = dateFns.getDay(startOfMonth);
	let colStart = props.startDay;
	if (colStart > startWeekday) colStart -= daysInWeek;
	
	const transactions = useSelector<any, any>(state => state.appState.transactions);
	const unreconciledLines = useSelector<any, StatementLine[]>(state => state.appState.unreconciledLines);
	let transactionsByDay = new Map<number, Transaction[]>();
	let unreconciledLinesByDay = new Map<number, StatementLine[]>();
	if(transactions != null)
		Object.values(transactions).forEach((transaction: Transaction) => {
			if(dateFns.compareAsc(startOfMonth, transaction.date) <= 0 && dateFns.compareAsc(transaction.date, endOfMonth) <= 0) {
				const day = dateFns.getDate(transaction.date);
				if(transactionsByDay.get(day) == null) transactionsByDay.set(day, []);
				transactionsByDay.get(day).push(transaction);
			}
		});
	unreconciledLines.forEach(line => {
		if(dateFns.compareAsc(startOfMonth, line.date) <= 0 && dateFns.compareAsc(line.date, endOfMonth) <= 0) {
			const day = dateFns.getDate(line.date);
			if(unreconciledLinesByDay.get(day) == null) unreconciledLinesByDay.set(day, []);
			unreconciledLinesByDay.get(day).push(line);
		}
	});
	for(let i = props.startDay; i < props.startDay + daysInWeek; i++)
		headers.push(<div key={"header-" + i.toString()} className="header">{props.header && props.header(i % daysInWeek)}</div>);			
	for(let i = colStart; i < startWeekday; i++)
		cells.push(<div key={v1()} className="empty-cell"></div>);
	for (let date = startOfMonth; dateFns.compareAsc(date, endOfMonth) <= 0; date = dateFns.addDays(date, 1))
		cells.push(<div key={date.valueOf()} className="cell"><DayCell day={date} transactions={transactionsByDay.get(dateFns.getDate(date)) || []} unreconciledLines={unreconciledLinesByDay.get(dateFns.getDate(date)) || []} /></div>);
	for(let i = 1 + ((startWeekday - colStart) + dateFns.getDaysInMonth(startOfMonth) - 1) % daysInWeek; i < daysInWeek; i++)
		cells.push(<div key={v1()} className="empty-cell"></div>);
	return <>
	<div className="weekdays">
	{headers}
	</div>
	<div className="calendar" {...swipeHandlers}>{cells}</div>
	</>;
}
