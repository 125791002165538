import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {TransactionPattern, TransactionLinePattern} from "@src/Models";
import {Overlay} from "@src/Utils";
import {updateTransactionPattern, deleteTransactionPattern} from "@src/Actions";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";

function TransactionPatternEditor(props: {
	id: number;
}) {
	const transactionPatterns = useSelector<any, any>(state => state.appState.transactionPatterns);
	const linePatterns = useSelector<any, any>(state => state.appState.linePatterns);
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [selectedLines, setSelectedLines] = React.useState<number[]>([]);
	const [transactionPattern, setTransactionPattern] = React.useState<TransactionPattern>(null);
	React.useEffect(() => {
		setTransactionPattern(props.id == null ? {
			description: "新規",
			lines: []
		} : transactionPatterns[props.id]);
		if(transactionPattern != null) setSelectedLines(transactionPattern.lines);
	}, [showEditor]);
	const dispatch = useDispatch();
	function save() {
		apiClient.saveTransactionPattern(transactionPattern)
		.then(savedTransactionPattern => apiClient.setTransactionPatternLines(savedTransactionPattern.id, selectedLines.filter(x => x != null)).then(() => savedTransactionPattern))
		.then(savedTransactionPattern => {savedTransactionPattern.lines = selectedLines.filter(x => x != null); dispatch(updateTransactionPattern(savedTransactionPattern)); setShowEditor(false); })
	}
	function deleteRecord() {
		apiClient.deleteTransactionPattern(transactionPattern.id).then(() => { dispatch(deleteTransactionPattern(transactionPattern.id)); setShowEditor(false); })
	}
	if(transactionPattern == null) return null;
	return <li onClick={() => setShowEditor(true)}>
		{transactionPattern.description}
		{!showEditor || <Overlay onDismiss={() => setShowEditor(false)}>
		<div className="transaction-pattern-editor">
		<div className="field"><label>名称</label>
		<input value={transactionPattern.description} onChange={e => setTransactionPattern({...transactionPattern, description: e.target.value})} />
		</div>
		<label>明細パターン</label>
		<ul>
		{Object.values(linePatterns).map((linePattern: TransactionLinePattern) =>
			<li key={linePattern.id} onClick={() => setSelectedLines([...selectedLines, linePattern.id])}>{linePattern.description}</li>)}
		</ul>
		<label>パターンの明細</label>
		<ul>
		{selectedLines.map((id, index) => selectedLines[index] == null ||
			<li key={index}
			onClick={() => {selectedLines[index] = null; setSelectedLines([...selectedLines]);}}>
			{linePatterns[id].description}</li>)}
		</ul>
		<Button variant="primary" onClick={save}>保存</Button>
		<Button variant="primary" onClick={() => setShowEditor(false)}>キャンセル</Button>
		{props.id == null || <Button variant="primary" onClick={deleteRecord}>削除</Button>}
		</div>
		</Overlay>}
	</li>;
}

export function TransactionPatternsManager() {
	const transactionPatterns = useSelector<any, any>(state => state.appState.transactionPatterns);
	return <>
	<ul className="transaction-patterns-manager">
	{Object.values(transactionPatterns).map((transactionPattern: TransactionPattern) => <TransactionPatternEditor key={transactionPattern.id} id={transactionPattern.id} />)}
	<TransactionPatternEditor key="new" id={null} />
	</ul>
	</>;
}
