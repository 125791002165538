import * as React from "react";
import * as apiClient from "@src/services/ApiClient";
import {BalanceCheckSchedule, Account, Currency, TransactionLineSide, creditAccounts} from "@src/Models";
import {Overlay} from "@src/Utils";
import {AmountField} from "@src/AmountField";
import {useSelector, useDispatch} from "react-redux";
import * as dateFns from "date-fns";
import Button from "react-bootstrap/Button";
import {addTransaction} from "@src/Actions";

function BalanceCheckerModal(props: {
	associatedSchedule: BalanceCheckSchedule;
	onUpdateDone: () => void;
	onDismiss: () => void;
}) {
	const [balance, setBalance] = React.useState<number>(null);
	const [checkedBalance, setCheckedBalance] = React.useState<number>(null);
	const bookParams = useSelector<any, any>(state => state.appState.bookParams);
	const dispatch = useDispatch();
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	const dataFetching: any = useSelector<any, any>(state => state.appState.dataFetching);
	React.useEffect(() => {
		apiClient.getBalanceSheet(new Date()).then(balanceList => {
			for(let balance of balanceList) {
				if(balance.account == props.associatedSchedule.accountId) {
					setCheckedBalance(balance.accountAmount);
					setBalance(balance.accountAmount);
					break;
				}
			}
		});
	}, []);
	function sendCheck() {
		apiClient.reportBalance(props.associatedSchedule.accountId, checkedBalance).then(props.onUpdateDone)
			.then(() => {
				if(balance != checkedBalance) {
					let side: TransactionLineSide;
					if(creditAccounts.includes(account.accountType))
						side = balance > checkedBalance ? TransactionLineSide.debit : TransactionLineSide.credit;
					else
						side = balance < checkedBalance ? TransactionLineSide.debit : TransactionLineSide.credit;
					apiClient.postTransaction({
						id: null,
						date: new Date(),
						lines: [
							{side: side, account: props.associatedSchedule.accountId, inputAmount: Math.abs(balance - checkedBalance), tags: [],
							description: "調整", icon: "purse"},
							{side: side == TransactionLineSide.debit ? TransactionLineSide.credit : TransactionLineSide.debit,
							account: bookParams.accountForUnbalanced,
							inputAmount: Math.abs(balance - checkedBalance), tags: [],
							description: "調整", icon: "purse"}
						]
					}).then(transaction => dispatch(addTransaction(transaction)));
				}
			});
	}
	if(dataFetching || balance == null) return null;
	let account: Account = accounts[props.associatedSchedule.accountId];
	let currency: Currency = currencies[account.currency];
	return <Overlay onDismiss={props.onDismiss}>
	<div className="balance-checker">
	<h2>{account.name}</h2>
	Canemone上の残高: {balance} {currency.symbol}<br />
	実際の残高: <AmountField initialValue={balance} currency={currency.code} onChange={setCheckedBalance} /><br />
	最後の確認: {props.associatedSchedule.lastCheck == null ? "-" : dateFns.format(new Date(props.associatedSchedule.lastCheck), "yyyy/MM/dd HH:mm:ss")}<br />
	<Button variant="primary" onClick={sendCheck}>確認</Button>
	<Button variant="primary" onClick={props.onDismiss}>後で</Button>
	</div>
	</Overlay>;
}

export function BalanceChecker() {
	function snooze() {
		setSnoozeUntil(new Date(new Date().getTime() + 60*60*1000))
	}
	const now = new Date().getTime();
	const [checkTasks, setCheckTasks] = React.useState<BalanceCheckSchedule[]>(null);
	const [snoozeUntil, setSnoozeUntil] = React.useState<Date>(null);
	const [lastUpdate, setLastUpdate] = React.useState<Date>(new Date());
	React.useEffect(() => {
		apiClient.getCheckSchedules().then(setCheckTasks);
	}, [lastUpdate]);
	if(now - lastUpdate.getTime() > 15*60*1000) setLastUpdate(new Date());
	if(checkTasks == null || checkTasks.length == 0) return null;
	let dueTasks = checkTasks.filter(task => task.date.getTime() < now);
	if(dueTasks.length == 0 || (snoozeUntil != null && snoozeUntil.getTime() > now)) return null;
	return <BalanceCheckerModal key={dueTasks[0].accountId} associatedSchedule={dueTasks[0]} onUpdateDone={() => setLastUpdate(new Date())} onDismiss={snooze} />;
}

export function BalanceCheckerButton(props: {
	associatedSchedule: BalanceCheckSchedule;
}) {
	const [modalDisplayed, setModalDisplayed] = React.useState<boolean>(false);
	return <><Button variant="primary" onClick={() => setModalDisplayed(true)}>残高確認</Button>
	{!modalDisplayed || 
		<BalanceCheckerModal associatedSchedule={props.associatedSchedule} onUpdateDone={() => setModalDisplayed(false)} onDismiss={() => setModalDisplayed(false)} />
	}</>;
}