import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {Currency} from "@src/Models";
import {Overlay} from "@src/Utils";
import {updateCurrency, deleteCurrency} from "@src/Actions";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";

function CurrencyEditor(props: {
	code: string;
}) {
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [currency, setCurrency] = React.useState<Currency>(null);
	React.useEffect(() => setCurrency(props.code == null ? {
		name: "新規"
	} : currencies[props.code]), [showEditor]);
	const dispatch = useDispatch();
	function save() {
		if(props.code == null)
			apiClient.saveCurrency(currency).then(currency => { dispatch(updateCurrency(currency)); setShowEditor(false); })
		else
			apiClient.updateCurrency(currency).then(currency => { dispatch(updateCurrency(currency)); setShowEditor(false); })
	}
	function deleteRecord() {
		apiClient.deleteCurrency(currency.code).then(() => { dispatch(deleteCurrency(currency.code)); setShowEditor(false); })
	}
	if(currency == null) return null;
	return <li onClick={() => setShowEditor(true)}>
		{currency.name}
		{!showEditor || <Overlay onDismiss={() => setShowEditor(false)}>
		<div className="currency-editor">
		<div className="field"><label>通貨コード</label>
		<input value={currency.code} onChange={e => setCurrency({...currency, code: e.target.value})} readOnly={props.code != null} />
		</div>
		<div className="field"><label>通貨名</label>
		<input value={currency.name} onChange={e => setCurrency({...currency, name: e.target.value})} />
		</div>
		<div className="field"><label>シンボル</label>
		<input value={currency.symbol} onChange={e => setCurrency({...currency, symbol: e.target.value})} />
		</div>
		<div className="field"><label>小数点</label>
		<input type="number" value={currency.decimals} onChange={e => setCurrency({...currency, decimals: e.target.valueAsNumber})} />
		</div>
		<Button variant="primary" onClick={save}>保存</Button>
		<Button variant="primary" onClick={() => setShowEditor(false)}>キャンセル</Button>
		{props.code == null || <Button variant="primary" onClick={deleteRecord}>削除</Button>}
		</div>
		</Overlay>}
	</li>;
}

export function CurrenciesManager() {
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	return <>
	<ul className="currencies-manager">
	{Object.values(currencies).map((currency: Currency) => <CurrencyEditor key={currency.code} code={currency.code} />)}
	<CurrencyEditor key="new" code={null} />
	</ul>
	</>;
}
