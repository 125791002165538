import * as React from "react";
import * as dateFns from 'date-fns';
import Button from "react-bootstrap/Button";
import {useSelector, useDispatch} from "react-redux";
import {Transaction, TransactionLine, Account, AccountType, StatementLine, 
	creditAccounts, TransactionLineSide} from "@src/Models";
import Link from "redux-first-router-link";
import {dayDetails, newTransaction} from "@src/Actions";
import {useSwipeable} from 'react-swipeable';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {AmountDisplay} from "@src/Utils";

export function DaySelector() {
	const location = useSelector<any, any>(state => state.location);
	const dispatch = useDispatch();
	const {year, month, day} = location.payload;
	const currentDay = new Date(year, month - 1, day, 0, 0, 0);
	function move(step: number) {
		dispatch(dayDetails(dateFns.addDays(currentDay, step)));
	}
	function moveToday() {
		dispatch(dayDetails(new Date()));
	}
	return <>
		<Button variant="light" onClick={() => move(-1)}>&lt;</Button>
		<span className="day">{dateFns.format(currentDay, "yyyy/MM/dd")}
		<img src="/api/ico/calendar.svg" className="today" alt="Today" onClick={moveToday} /></span>
		<Button variant="light" onClick={() => move(+1)}>&gt;</Button>
		</>;
}
function LineList(props: {
	lines: TransactionLine[]
}) {
	const accountsMap: any = useSelector<any, any>(state => state.appState.accounts);
	return <ul className="transaction-list">
		{props.lines.map((line, index) => {
			return <li key={index}>
			<Link to={"/transaction/" + (line as any).parent}>
				<span className="title">{(accountsMap[line.account] as Account).name + (line.purpose != null ? " - " + line.purpose : "")}</span>
					<span className="amount"><AmountDisplay amount={line.accountAmount} currencyCode={accountsMap[line.account].currency} /></span>
			</Link>
			</li>;
		})}
	</ul>;
}
function StatementLineList(props: {
	lines: StatementLine[]
}) {
	const accountsMap: any = useSelector<any, any>(state => state.appState.accounts);
	const dispatch = useDispatch();
	function generateTransactionStub(line: StatementLine): Transaction {
		const account: Account = accountsMap[line.accountId];
		let side: TransactionLineSide;
		if(line.amount >= 0)
			side = creditAccounts.includes(account.accountType) ? TransactionLineSide.credit : TransactionLineSide.debit;
		else
			side = creditAccounts.includes(account.accountType) ? TransactionLineSide.debit : TransactionLineSide.credit;
		return {
			id: null,
			date: line.date,
			lines: [
				{account: line.accountId, side: side, inputAmount: Math.abs(line.amount),
					description: side == TransactionLineSide.credit ? "貸し方" : "借り方",
					icon: side == TransactionLineSide.credit ? "kashikata" : "karikata", tags: []
				}
			]
		};
	}
	return <ul className="statement-list">
		{props.lines.map((line, index) => {
			return <li key={index} onClick={() => dispatch(newTransaction(generateTransactionStub(line)))}>
			<div className="unreconciled-item">
			<span className="title">{line.description}</span>
			<span className="amount"><AmountDisplay amount={line.amount} currencyCode={accountsMap[line.accountId].currency} /></span>
			<span className="account">{(accountsMap[line.accountId] as Account).name}</span>
			</div></li>;
		})}
	</ul>;
}
export function DayDetails() {
	const location = useSelector<any, any>(state => state.location);
	const {year, month, day} = location.payload;
	const transactionsMap: any = useSelector<any, any>(state => state.appState.transactions);
	const bookParams: any = useSelector<any, any>(state => state.appState.bookParams);
	const dataFetching: any = useSelector<any, any>(state => state.appState.dataFetching);
	const allUnreconciledLines: StatementLine[] = useSelector<any, StatementLine[]>(state => state.appState.unreconciledLines);
	const transactions: Transaction[] = Object.values(transactionsMap).filter((transaction: Transaction) => 
		transaction.date.getFullYear() == Number.parseInt(year) &&
		transaction.date.getMonth() == Number.parseInt(month) - 1 &&
		transaction.date.getDate() == Number.parseInt(day)) as Transaction[];
	const unreconciledLines: StatementLine[] = allUnreconciledLines.filter((line: StatementLine) => 
		line.date.getFullYear() == Number.parseInt(year) &&
		line.date.getMonth() == Number.parseInt(month) - 1 &&
		line.date.getDate() == Number.parseInt(day));
	const dispatch = useDispatch();
	const date = new Date(year, month - 1, day);
	const accountsMap: any = useSelector<any, any>(state => state.appState.accounts);
	const handlers = useSwipeable({
		onSwipedLeft: () => {dispatch(dayDetails(dateFns.addDays(date, 1)))},
		onSwipedRight: () => {dispatch(dayDetails(dateFns.addDays(date, -1)))}
	});
	if(dataFetching) return null;
	let expenses: TransactionLine[] = [];
	let expensesTotal: number = 0;
	let revenues: TransactionLine[] = [];
	let revenuesTotal: number = 0;
	let others: TransactionLine[] = [];
	let unbalanced: TransactionLine[] = [];
	transactions.forEach(transaction => {
		let localOthers: TransactionLine[] = [];
		let isTransactionUnbalanced = false;
		transaction.lines.forEach(line => {
			let account: Account = accountsMap[line.account];
			(line as any).parent = transaction.id;
			switch(account.accountType) {
				case AccountType.expense:
					expenses.push(line);
					expensesTotal += line.bookAmount;
				break;
				case AccountType.revenue:
					revenues.push(line);
					revenuesTotal += line.bookAmount;
				break;
				default:
					if(line.account == bookParams.accountForUnbalanced)
						isTransactionUnbalanced = true;
					else
						localOthers.push(line);
			}
		});
		if(isTransactionUnbalanced)
			unbalanced.push(...localOthers);
		else
			others.push(...localOthers);
	});
	return <div {...handlers}><Tabs defaultActiveKey="expenses" id="day-details">
	<Tab eventKey="expenses" title="出費">
		<LineList lines={expenses} />
		<span className="total"><AmountDisplay amount={expensesTotal} currencyCode={bookParams.bookCurrency} /></span>
	</Tab>
	<Tab eventKey="revenues" title="収入">
		<LineList lines={revenues} />
		<span className="total"><AmountDisplay amount={revenuesTotal} currencyCode={bookParams.bookCurrency} /></span>
	</Tab>
	<Tab eventKey="others" title="その他">
		<LineList lines={others} />
	</Tab>
	{(unreconciledLines.length == 0 && unbalanced.length == 0) ||
	<Tab eventKey="unreconciledLines" title="未登録">
		<StatementLineList lines={unreconciledLines} />
		<LineList lines={unbalanced} />
	</Tab>
	}
	</Tabs></div>;
}
