import * as React from "react";
import * as dateFns from "date-fns";
import {useSelector, useDispatch} from "react-redux";
import {AccountLine, Account} from "@src/Models";
import {openTransaction} from "@src/Actions";
import Button from "react-bootstrap/Button";
import {accountLines as accountLinesAction} from "@src/Actions";
import Link from "redux-first-router-link";
import {AmountDisplay} from "@src/Utils";

export function AccountLines() {
	const fromDateParam = useSelector<any, string>(state => state.location.query.from);
	const toDateParam = useSelector<any, string>(state => state.location.query.to);
	const accountIdParam = useSelector<any, string>(state => state.location.query.accountId);
	const purposeParam = useSelector<any, string>(state => state.location.query.purpose);
	const dispatch = useDispatch();
	const accountLines = useSelector<any, AccountLine[]>(state => state.appState.accountLines);
	const dataFetching: boolean = useSelector<any, any>(state => state.appState.dataFetching);
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const [fromDate, setFromDate] = React.useState<string>(dateFns.format(new Date(fromDateParam), "yyyy-MM-dd'T'HH:mm:ss"));
	const [toDate, setToDate] = React.useState<string>(dateFns.format(new Date(toDateParam), "yyyy-MM-dd'T'HH:mm:ss"));
	const [accountId, setAccountId] = React.useState<string>(accountIdParam);
	const [purpose, setPurpose] = React.useState<string>(purposeParam);
	if(dataFetching) return null;
	const account: Account = accounts[accountId];
	return <>
		<h1>{account.name}{purpose != null ? " - " + purpose : ""}</h1>
		<input type="datetime-local" value={fromDate} onChange={e => setFromDate(e.target.value)} />
		<input type="datetime-local" value={toDate} onChange={e => setToDate(e.target.value)} />
		<Button onClick={() => dispatch(accountLinesAction(accountId, purpose, new Date(fromDate), new Date(toDate)))}>期間変更</Button>
		<ul className="transaction-list">
			{accountLines.map(line => <li key={line.lineId}><Link to={openTransaction(line.transactionId)}>
				<span className="title">{dateFns.format(line.date, "yyyy/MM/dd HH:mm:ss")}</span>
				<span className="amount"><AmountDisplay amount={line.accountAmount} currencyCode={account.currency} /></span>
				{line.accountStatementId == null ? <span className="no-check"></span> : <img className="check" src="/api/ico/check.svg" alt="check" />}
			</Link></li>)}
		</ul>
	</>;
}
