import {Currency, AccountTag, Account, Transaction, TransactionPattern,
	TransactionLinePattern, AccountBalance, AccountLine, StatementLine, BalanceCheckSchedule} from "@src/Models";
import * as apiClient from "@src/services/ApiClient";
import * as dateFns from "date-fns";

export function loadMasterData(currencies: Map<string, Currency>,
	accountTags: Map<string, AccountTag>,
	accounts: Map<string, Account>,
	bookParams: any) {
	return {
		type: "loadMasterData",
		currencies: currencies,
		accountTags: accountTags,
		accounts: accounts,
		bookParams: bookParams,
		masterDataDate: new Date(),
	};
}

function loadTransactions(transactions: Transaction[]) {
	return {
		type: "loadTransactions",
		transactions: transactions.reduce((result: any, value: Transaction) => {return {...result, [value.id]: value}}, {})
	};
}

export function addTransaction(transaction: Transaction) {
	return {
		type: "addTransaction",
		transaction: transaction
	}
}

function loadUnreconciledLines(lines: StatementLine[]) {
	return {
		type: "loadUnreconciledLines",
		data: lines
	};
}

export async function loadMasterDataThunk(dispatch: any, getState: any) {
	if(getState().appState.masterDataDate != null) return;
	const [
		currencies,
		accountTags,
		accounts,
		bookParams
	] = await Promise.all([
		apiClient.getCurrencies(),
		apiClient.getAccountTags(),
		apiClient.getAccounts(),
		apiClient.getBookParams()
	]);
	let currenciesMap = currencies.reduce((result: any, value: Currency) => {return {...result, [value.code]: value}}, {});
	let accountTagsMap = accountTags.reduce((result: any, value: AccountTag) => {return {...result, [value.id]: value}}, {});
	let accountsMap = accounts.reduce((result: any, value: Account) => {return {...result, [value.id]: value}}, {});
	dispatch(loadMasterData(currenciesMap, accountTagsMap, accountsMap, bookParams));
}

export async function ensureTransactionRangeThunk(dispatch: any, getState: any) {
	try {
		await loadMasterDataThunk(dispatch, getState);
		const {year, month} = getState().location.payload;
		const date = new Date(year, month - 1, 1);
		if(getState().location.prev.payload.year != year || getState().location.prev.payload.month != month) {
			dispatch(loadTransactions(await apiClient.getTransactions({
				"from": dateFns.startOfMonth(date).toISOString(),
				"to": dateFns.addMonths(dateFns.startOfMonth(date), 1).toISOString(),
			})));
			dispatch(loadUnreconciledLines(await apiClient.getUnreconciledLines()));
		}
	} finally {
		dispatch({type: "fetchingComplete"});
	}
}

export async function prepareTransactionPatternsThunk(dispatch: any, getState: any) {
	await loadMasterDataThunk(dispatch, getState);
	dispatch(loadLinePatterns(await apiClient.getTransactionLinePatterns()));
	dispatch(loadIcons(await apiClient.getIcons()));
	dispatch(loadTransactionPatterns(await apiClient.getTransactionPatterns()));
}

export async function prepareTransactionEditorThunk(dispatch: any, getState: any) {
	try {
		await loadMasterDataThunk(dispatch, getState);
		await prepareTransactionPatternsThunk(dispatch, getState);
		const {id} = getState().location.payload;
		let transaction: Transaction;
		if(id != "new") {
			transaction = getState().appState.transactions[id];
			if(transaction == null) {
				transaction = (await apiClient.getTransactions({
					"id": id
				}))[0];
			}
		} else {
			transaction = getState().location.payload.transaction;
			if(transaction == null) {
				transaction = {
					id: null,
					date: new Date(),
					lines: [],
				};
			}
		}
		dispatch({type: "loadTransactionEditor", transaction: transaction});
	} finally {
		dispatch({type: "fetchingComplete"});
	}
}

export function calendar(date: Date) {
	return {
		type: "calendar",
		payload: {
			"year": dateFns.format(date, "yyyy"),
			"month": dateFns.format(date, "MM"),
		}
	}
}

export function dayDetails(date: Date) {
	return {
		type: "dayDetails",
		payload: {
			"year": dateFns.format(date, "yyyy"),
			"month": dateFns.format(date, "MM"),
			"day": dateFns.format(date, "dd"),
		}
	}
}

export function newTransaction(template: Transaction) {
	return { type: 'transactionEditor', payload:
		{ id: "new", transaction: template } };
}

export async function profitLossThunk(dispatch: any, getState: any) {
	const state = getState();
	if(state.location.query == null || state.location.query.from == null || state.location.query.to == null )
		dispatch({
			type: "profitLoss",
			query: {
				"from": dateFns.startOfMonth(new Date()).toISOString(),
				"to": new Date().toISOString(),
			}
		});
	else {
		await loadMasterDataThunk(dispatch, getState);
		dispatch(loadProfitLossData(await apiClient.getProfitAndLoss(new Date(state.location.query.from), new Date(state.location.query.to))));
		dispatch({type: "fetchingComplete"});
	}
}
export async function balanceSheetThunk(dispatch: any, getState: any) {
	const state = getState();
	if(state.location.query == null || state.location.query.date == null )
		dispatch({
			type: "balanceSheet",
			query: {
				"date": new Date().toISOString(),
			}
		});
	else {
		await loadMasterDataThunk(dispatch, getState);
		dispatch(loadBalanceSheetData(await apiClient.getBalanceSheet(new Date(state.location.query.date))));
		dispatch({type: "fetchingComplete"});
	}
}

function loadTransactionPatterns(transactionPatterns: TransactionPattern[]) {
	return {
		type: "loadTransactionPatterns",
		data: transactionPatterns.reduce((result: any, value: TransactionPattern) => {return {...result, [value.id]: value}}, {})
	};
}

function loadLinePatterns(linePatterns: TransactionLinePattern[]) {
	return {
		type: "loadLinePatterns",
		data: linePatterns.reduce((result: any, value: TransactionLinePattern) => {return {...result, [value.id]: value}}, {})
	};
}

function loadIcons(icons: string[]) {
	return {
		type: "loadIcons",
		data: icons
	};
}

export function loadBalanceSheetData(data: AccountBalance[]) {
	return { type: "loadBalanceSheetData", data: data };
}

export function loadProfitLossData(data: AccountBalance[]) {
	return { type: "loadProfitLossData", data: data };
}

export function loadAccountLinesData(data: AccountLine[]) {
	return { type: "loadAccountLinesData", data: data };
}

export function openTransaction(id: number) {
	return {type: "transactionEditor", payload: {id: id}};
}

export async function accountLinesThunk(dispatch: any, getState: any) {
	await loadMasterDataThunk(dispatch, getState);
	const state = getState();
	if(!(state.location.query == null || state.location.query.accountId == null ||
		state.location.query.from == null || state.location.query.to == null)) {
		dispatch(loadAccountLinesData(await apiClient.getAccountDetails(state.location.query.accountId,
			state.location.query.purpose,
			new Date(state.location.query.from),
			new Date(state.location.query.to))));
	}
	dispatch({type: "fetchingComplete"});
}

export function accountLines(accountId: string, purpose: string, dateFrom: Date, dateTo: Date) {
	return {
		type: "accountLines",
		query: {
			"accountId": accountId,
			"purpose": purpose,
			"from": dateFrom.toISOString(),
			"to": dateTo.toISOString(),
		}		
	};
}

export function updateAccountTag(accountTag: AccountTag) {
	return {
		type: "updateAccountTag",
		data: accountTag
	}
}

export function deleteAccountTag(id: number) {
	return {
		type: "deleteAccountTag",
		id: id
	}
}

export function updateCurrency(currency: Currency) {
	return {
		type: "updateCurrency",
		data: currency
	}
}

export function deleteCurrency(code: string) {
	return {
		type: "deleteCurrency",
		id: code
	}
}

export function updateAccount(account: Account) {
	return {
		type: "updateAccount",
		data: account
	}
}

export function deleteAccount(id: string) {
	return {
		type: "deleteCurrency",
		id: id
	}
}

export function updateLinePattern(linePattern: TransactionLinePattern) {
	return {
		type: "updateLinePattern",
		data: linePattern
	}
}

export function deleteLinePattern(id: number) {
	return {
		type: "deleteLinePattern",
		id: id
	}
}

export function updateTransactionPattern(transactionPattern: TransactionPattern) {
	return {
		type: "updateTransactionPattern",
		data: transactionPattern
	}
}

export function deleteTransactionPattern(id: number) {
	return {
		type: "deleteTransactionPattern",
		id: id
	}
}
