import * as React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Transaction, TransactionLine, TransactionLineSide, Account, TransactionLinePattern, TransactionPattern} from "@src/Models";
import {AmountField} from "@src/AmountField";
import * as dateFns from "date-fns";
import {Overlay} from "@src/Utils";
import * as apiClient from "@src/services/ApiClient";
import {useSelector, useDispatch} from "react-redux";
import {newTransaction, dayDetails} from "@src/Actions";

function TagsSelector(props: {
	onChange: (newValue: number[]) => void;
	value: number[];
	accounts: Account[];
}) {
	const accountTags = useSelector<any, any>(state => state.appState.accountTags);
	const [collapsed, setCollapsed] = React.useState<boolean>(true);
	function doRemoveValue(category: number) {
		props.onChange(props.value.filter((value) => value != category));
	}
	function doAddValue(category: number) {
		props.onChange([...props.value, category]);
	}
	let availableTags: number[] = [];
	props.accounts.forEach((account: Account) => {
		account.tags.forEach((tag: number) => {
			if (props.value.indexOf(tag) == -1 && availableTags.indexOf(tag) == -1)
				availableTags.push(tag);
		})
	});
	if(collapsed) return <button className="collapse-trigger" onClick={() => setCollapsed(false)}>他の項目を選択</button>;
	return <>
	<button className="collapse-trigger" onClick={() => setCollapsed(true)}>閉じる</button>
	{props.value.length == 0 || 
	<>選択中タグ:<ul className="tag-selector">
	{props.value.map((tag: number) => <li className="selected-tag" key={tag} onClick={() => doRemoveValue(tag)}><span className="tag-label">{accountTags[tag].tag}</span><span className="tag-action">&times;</span></li>)}
	</ul></>}
	{availableTags.length == 0 ||
	<>タグを絞り込む:
	<ul className="tag-selector">
	{availableTags.map((tag: number) => <li className="unselected-tag" key={tag} onClick={() => doAddValue(tag)}><span className="tag-label">{accountTags[tag].tag}</span><span className="tag-action">+</span></li>)}
	</ul></>}
	</>;
}
function AccountSelector(props: {
	onDismiss: () => void;
	initialTags: number[];
	description: string;
	icon: string;
	onSelection: (newValue: string, purpose: string) => void;
}) {
	const [selectedTags, setSelectedTags] = React.useState<number[]>(props.initialTags == null ? [] : props.initialTags);
	const [refiningAccount, setRefiningAccount] = React.useState<Account>(null);
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	function doSelection(newValue: Account, purpose: string) {
		if (!newValue.needsPurpose || (purpose != null && purpose != '')) {
			props.onSelection(newValue.id, purpose);
			props.onDismiss();
		} else
			setRefiningAccount(newValue);
	}
	let filteredAccounts: Account[] = Object.values(accounts).filter((account: Account) =>
		selectedTags.length == 0 || account.tags.filter((item: number) => selectedTags.indexOf(item) > -1).length == selectedTags.length
	) as Account[];
	return <><Overlay onDismiss={props.onDismiss}><div className="account-selector">
		<h2><img src={"/api/ico/" + props.icon + ".svg"} alt={props.icon} />{props.description}</h2>
		<div className="body">
		{filteredAccounts.map((account: Account) => <span onClick={() => doSelection(account, null)} key={account.id} className="account-item">{account.name}</span>)}
	</div>
	<TagsSelector onChange={setSelectedTags} value={selectedTags} accounts={filteredAccounts} />
	</div></Overlay>
	{refiningAccount != null &&
	<PurposeSelector value={refiningAccount.id} onDismiss={() => setRefiningAccount(null)} onChange={(account, purpose) => doSelection(accounts[account], purpose)}></PurposeSelector>
	}
	</>;
}
function FreePurposeSelector(props: {
	onChange: (newValue: string) => void
}) {
	const [freeInput, setFreeInput] = React.useState<string>('');
	return <div className="free-input">
	<input type="text" value={freeInput} onChange={e => setFreeInput(e.target.value)} />
	<Button variant="primary" onClick={() => props.onChange(freeInput)}>OK</Button>
	</div>;
}
function PurposeSelector(props: {
	value: string;
	onChange: (newValue: string, purpose: string) => void;
	onDismiss: () => void;
}) {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const [purposes, setPurposes] = React.useState<string[]>();
	React.useEffect(() => {apiClient.getPurposes(accounts[props.value].id).then(setPurposes)}, []);
	if(purposes == null) return null;
	function doChange(s: string) {
		props.onChange(props.value, s);
	}
	return <Overlay onDismiss={props.onDismiss}><Card className="purpose-selector">
	<Card.Header>{accounts[props.value].name}</Card.Header>
	<Card.Body>
	<ul>{purposes.map((purpose: string) => <li key={purpose} onClick={() => doChange(purpose)}>{purpose}</li>)}</ul>
	<FreePurposeSelector onChange={doChange} />
	</Card.Body>
	</Card></Overlay>;
}
function AccountField(props: {
	value: string;
	tags: number[];
	description: string;
	icon: string;
	onChange: (newValue: string, purpose: string) => void;
	purpose: string;
}) {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const [selectorVisible, showSelector] = React.useState<boolean>(false);
	return <div className="field">
		<label><img src={"/api/ico/" + props.icon + ".svg"} alt={props.icon} />{props.description}</label>
		{props.value == null ? <span className="account-name empty-value" onClick={() => showSelector(true)}>勘定科目を選択</span> : <span className="account-name" onClick={() => showSelector(true)}>{accounts[props.value].name + (accounts[props.value].needsPurpose ? " - " + props.purpose : "")}</span>}
		{!selectorVisible || <AccountSelector icon={props.icon} description={props.description} onSelection={(selection, purpose) => props.onChange(selection, purpose)} initialTags={props.tags} onDismiss={() => showSelector(false)} />}
		</div>;
}
function LineEditor(props: {
	lineIndex: number;
	onChange: (line: TransactionLine) => void;
}) {
	const transaction = useSelector<any, Transaction>(state => state.appState.transactionEditor);
	const line = transaction.lines[props.lineIndex];
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	let firstCurrency = null;
	let linesHaveSameCurrency = true;
	let missingAmounts = 0;
	for(let i = 0; i < transaction.lines.length; i++) {
		if(transaction.lines[i].account != null) {
			if(firstCurrency == null)
				firstCurrency = accounts[transaction.lines[i].account].currency;
			else
				if(accounts[transaction.lines[i].account].currency != firstCurrency) linesHaveSameCurrency = false;
		}
		if(transaction.lines[i].inputAmount == null)
			missingAmounts++;
	}
	let amountIsNecessary: boolean = !(missingAmounts == 1 && linesHaveSameCurrency);
	return <div className={"transaction-line " + (line.side == TransactionLineSide.debit ? "debit-line" : "credit-line")}>
		<AccountField value={line.account} tags={line.tags != null ? line.tags : accounts[line.account].tags}
			description={line.description} purpose={line.purpose} icon={line.icon}
			onChange={(account: string, purpose: string) =>
				props.onChange({...line, account: account, purpose: purpose})} />
		{line.account == null || (line.inputAmount == null && !amountIsNecessary) ||
			<AmountField initialValue={line.inputAmount} currency={accounts[line.account].currency} onChange={(newValue: number) => 
				props.onChange({
					...line,
					inputAmount: newValue
				})
			} />}
	</div>;
}
function AddLine() {
	const [selectorVisible, showSelector] = React.useState<boolean>(false);
	const transaction = useSelector<any, Transaction>(state => state.appState.transactionEditor);
	const dispatch = useDispatch();
	const linePatterns = useSelector<any, any>(state => state.appState.linePatterns);
	function doAddLine(line: TransactionLine) {
		dispatch(addLines(transaction, [line]));
	}
	return <><Button variant="primary" onClick={() => showSelector(true)}>行を追加</Button>
	{selectorVisible && <Overlay onDismiss={() => showSelector(false)}>
	<ul className="line-pattern-selector">
	{Object.values(linePatterns).map((pattern: TransactionLinePattern) => 
		<li key={pattern.id} onClick={() => {doAddLine(pattern); showSelector(false);}}>{pattern.description}</li>)
	}
	</ul>
	</Overlay>}
	</>;
}
function TransactionPatternsSelector() {
	const transactionPatterns = useSelector<any, any>(state => state.appState.transactionPatterns);
	const linePatterns = useSelector<any, any>(state => state.appState.linePatterns);
	const transaction = useSelector<any, Transaction>(state => state.appState.transactionEditor);
	const dispatch = useDispatch();
	function executePattern(pattern: TransactionPattern) {
		dispatch(updateTransactionEditor({
			...transaction,
			lines: [
				...transaction.lines,
				...pattern.lines.map(lineId => linePatterns[lineId])
			]
		}));
	}
	return <ul className="transaction-pattern-selector">{Object.values(transactionPatterns).map((value: TransactionPattern) => 
		<li key={value.id}><Button variant="primary" onClick={() => executePattern(value)}>{value.description}</Button></li>
	)}</ul>;
}
function updateTransactionEditor(transaction: Transaction) {
	return {type: "loadTransactionEditor", transaction: transaction};
}
function addLines(transaction: Transaction, lines: TransactionLine[]) {
	return updateTransactionEditor({
			...transaction,
			lines: [
				...transaction.lines,
				...lines
			]
		});
}
export function TransactionEditor() {
	const dataFetching: boolean = useSelector<any, any>(state => state.appState.dataFetching);
	const transaction = useSelector<any, Transaction>(state => state.appState.transactionEditor);
	const dispatch = useDispatch();
	if(dataFetching === true || transaction == null) return null;
	function removeLine(targetIndex: number) {
		dispatch(updateTransactionEditor({
			...transaction,
			lines: transaction.lines.filter((value, index) => index != targetIndex)
		}));
	}
	async function save(transaction: Transaction) {
		apiClient.postTransaction(transaction)
		.then(() => dispatch(dayDetails(transaction.date)))
			.catch(error => alert((Object.values(JSON.parse(error.response.data.message)).reduce((s: any[], v: any[]) => [...s, ...v], []) as any[]).join("\n")));
	}
	async function deleteTransaction(transaction: Transaction) {
		if(confirm("本当に削除しますか?")) {
			apiClient.deleteTransaction(transaction).then(() => dispatch(dayDetails(transaction.date)));
		}
	}
	function cloneTransaction(transaction: Transaction) {
		let result = {...transaction, date: new Date(), id: null};
		return result;
	}
	return <div className="transaction-editor">
		<div className="field"><label><img src="/api/ico/clock.svg" alt="clock" />日時</label><input type="datetime-local" value={dateFns.format(transaction.date, "yyyy-MM-dd'T'HH:mm:ss")} onChange={e => 
			{if(e.target.value !== '')
				dispatch(updateTransactionEditor({...transaction, date: new Date(e.target.value)} as Transaction))
		}} /></div>
		{transaction.lines.length == 0 && <TransactionPatternsSelector />}
		{transaction.lines.length > 0 && <ul className="lines-list">{transaction.lines.map((line: TransactionLine, index: number) => 
			<li key={index}><LineEditor lineIndex={index} onChange={(newValue) =>
				dispatch(updateTransactionEditor({
					...transaction,
					lines: transaction.lines.map((oldValue, currentIndex) => index == currentIndex ? newValue : oldValue),
				}))
			} />
			<button className="delete-line" onClick={() => removeLine(index)}>&times;</button>
			</li>
		)}</ul>}
		<div className="action-buttons">
		<AddLine />
		<Button variant="primary" onClick={() => save(transaction)}>保存</Button>
        <Button variant="primary" onClick={() => history.back()}>キャンセル</Button>
		{transaction.id == null || <Button variant="primary" onClick={() => deleteTransaction(transaction)}>削除</Button>}
		{transaction.id == null || <Button variant="primary" onClick={() => dispatch(newTransaction(cloneTransaction(transaction)))}>複製</Button>}
		</div>
	</div>;
}