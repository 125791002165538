import * as React from "react";
import * as ReactDOM from "react-dom";
import {Overlay} from "@src/Utils";
import Button from "react-bootstrap/Button"

export function Calculator(props: {
	onReturn: (value: number | null) => void,
	initialValue?: number,
	onDismiss?: () => void
}) {
	function CalculatorButton(props: {keyCode: string, display: string, style?: React.CSSProperties}) {
		return <Button variant="outline-primary" onClick={(e) => {handleKey(props.keyCode); e.preventDefault();}} onKeyDown={e => e.preventDefault()} style={props.style}>{props.display}</Button>;
	}
	function keyHandler(e: KeyboardEvent) {
		handleKey(e.key);
		e.stopPropagation();
	};
	const [expr, setExpr] = React.useState<string>(props.initialValue ? props.initialValue.toString() : "");
	React.useEffect(() => {
		window.addEventListener('keydown', keyHandler);
		return () => window.removeEventListener('keydown', keyHandler);
	}, [expr]);
	function handleKey(key: string): void {
		if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '+', '-', '*', '/', '(', ')'].indexOf(key) != -1)
			setExpr(expr + key);
		if(key == 'Backspace')
			if(expr.length > 0) setExpr(expr.substring(0, expr.length - 1));
		if(key == 'Enter') {
			if (expr.length == 0)
				props.onReturn(null);
			else if(/^[0-9]*(\.[0-9]+){0,1}$/.test(expr))
				props.onReturn(Number(expr));
			else
				setExpr(eval(expr).toString());
		}
		if(key == 'Escape') props.onReturn(null);
	}
	function beautify(expr: string): string {
		return expr.replace(/\*/g, '×');
	}
	return <Overlay onDismiss={props.onDismiss}>
	<div className="calculator">
	<div className="display">{beautify(expr)}</div>
	<div className="keyboard">
	<CalculatorButton keyCode="(" display="(" />
	<CalculatorButton keyCode=")" display=")" />
	<CalculatorButton keyCode="/" display="÷" />
	<CalculatorButton keyCode="*" display="×" />
	<CalculatorButton keyCode="7" display="7" />
	<CalculatorButton keyCode="8" display="8" />
	<CalculatorButton keyCode="9" display="9" />
	<CalculatorButton keyCode="-" display="-" />
	<CalculatorButton keyCode="4" display="4" />
	<CalculatorButton keyCode="5" display="5" />
	<CalculatorButton keyCode="6" display="6" />
	<CalculatorButton keyCode="+" display="+" />
	<CalculatorButton keyCode="1" display="1" />
	<CalculatorButton keyCode="2" display="2" />
	<CalculatorButton keyCode="3" display="3" />
	<CalculatorButton keyCode="Enter" style={{gridRowEnd: "span 2"}} display="⏎" />
	<CalculatorButton keyCode="Backspace" display="⇦" />
	<CalculatorButton keyCode="0" display="0" />
	<CalculatorButton keyCode="." display="." />
	</div>
	</div>
	</Overlay>;
}
