import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {AccountBalance, Account, AccountType, Currency, BalanceCheckSchedule} from "@src/Models";
import {accountLines} from "@src/Actions";
import * as dateFns from "date-fns";
import Button from "react-bootstrap/Button";
import * as apiClient from "@src/services/ApiClient";
import {BalanceCheckerButton} from "@src/BalanceChecker";
import {AmountDisplay} from "@src/Utils";

function AccountBalanceList(props: {
	accountBalances: AccountBalance[],
	checkSchedules: BalanceCheckSchedule[],
	dateFrom: Date,
	dateTo: Date
}) {
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const dispatch = useDispatch();
	const checkSchedulesMap: any = props.checkSchedules.reduce((result, item) => { return {...result, [item.accountId]: item}; }, {});
	function goToAccountLines(account: string, purpose: string) {
		dispatch(accountLines(account, purpose, props.dateFrom, props.dateTo));
	}
	return <ul>{props.accountBalances.map(balance => {
			const account: Account = accounts[balance.account];
			return <li key={account.id + (account.needsPurpose ? "-" + balance.purpose : "")}>{account.name}{balance.purpose != null ? " - " + balance.purpose + ": ": ": "}
				<span className="amount"><AmountDisplay amount={balance.accountAmount} currencyCode={account.currency} /></span>
				<Button variant="primary" onClick={() => goToAccountLines(account.id, balance.purpose)}>詳細確認</Button>
				{Object.keys(checkSchedulesMap).includes(balance.account) &&
					<BalanceCheckerButton associatedSchedule={checkSchedulesMap[balance.account]} />
				}
			</li>;
		})}</ul>;
}

function summarizeData(data: any, accounts: any): any {
	return data.reduce((acc: any, value: AccountBalance) => {
		switch((accounts[value.account] as Account).accountType) {
			case AccountType.asset:
				return {
					...acc,
					assets: [...acc.assets, value],
					assetsAmount: acc.assetsAmount + value.bookAmount
				}
			case AccountType.liability:
				return {
					...acc,
					liabilities: [...acc.liabilities, value],
					liabilitiesAmount: acc.liabilitiesAmount + value.bookAmount
				}
			case AccountType.equity:
				return {
					...acc,
					equities: [...acc.equities, value],
					equityAmount: acc.equityAmount + value.bookAmount
				}
			case AccountType.expense:
				return {
					...acc,
					expenses: [...acc.expenses, value],
					expensesAmount: acc.expensesAmount + value.bookAmount
				}
			case AccountType.revenue:
				return {
					...acc,
					revenues: [...acc.revenues, value],
					revenuesAmount: acc.revenuesAmount + value.bookAmount
				}
		}
	}, {
		assets: [],
		liabilities: [],
		equities: [],
		expenses: [],
		revenues: [],
		assetsAmount: 0,
		liabilitiesAmount: 0,
		equityAmount: 0,
		expensesAmount: 0,
		revenuesAmount: 0,
	});
}

export function ProfitLoss() {
	const fromDateParam = useSelector<any, string>(state => state.location.query.from);
	const toDateParam = useSelector<any, string>(state => state.location.query.to);
	const dispatch = useDispatch();
	const [fromDate, setFromDate] = React.useState<string>(dateFns.format(new Date(fromDateParam), "yyyy-MM-dd'T'HH:mm:ss"));
	const [toDate, setToDate] = React.useState<string>(dateFns.format(new Date(toDateParam), "yyyy-MM-dd'T'HH:mm:ss"));
	const profitLossFlatData = useSelector<any, AccountBalance[]>(state => state.appState.profitLoss);
	const dataFetching: boolean = useSelector<any, any>(state => state.appState.dataFetching);
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const bookParams = useSelector<any, any>(state => state.appState.bookParams);
	const [checkTasks, setCheckTasks] = React.useState<BalanceCheckSchedule[]>(null);
	React.useEffect(() => {
		apiClient.getCheckSchedules().then(setCheckTasks);
	}, []);
	if(dataFetching || fromDateParam == null || toDateParam == null) return null;
	const profitLossData = summarizeData(profitLossFlatData, accounts);
	if(checkTasks == null) return null;
	return <>
	<h1>損益計算書</h1>
		<input type="datetime-local" value={fromDate} onChange={e => setFromDate(e.target.value)} />
		<input type="datetime-local" value={toDate} onChange={e => setToDate(e.target.value)} />
		<Button onClick={() => dispatch({
			type: "profitLoss",
			query: {
				"from": new Date(fromDate).toISOString(),
				"to": new Date(toDate).toISOString(),
			}
		})}>期間変更</Button>
		<h2>結果: <AmountDisplay amount={profitLossData.revenuesAmount - profitLossData.expensesAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<h2>出費: <AmountDisplay amount={profitLossData.expensesAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<AccountBalanceList checkSchedules={checkTasks} accountBalances={profitLossData.expenses} dateFrom={new Date(fromDate)} dateTo={new Date(toDate)} />
		<h2>収入: <AmountDisplay amount={profitLossData.revenuesAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<AccountBalanceList checkSchedules={checkTasks} accountBalances={profitLossData.revenues} dateFrom={new Date(fromDate)} dateTo={new Date(toDate)} />
	</>;
}

export function BalanceSheet() {
	const dateParam = useSelector<any, string>(state => state.location.query.date);
	const dispatch = useDispatch();
	const [date, setDate] = React.useState<string>(dateFns.format(new Date(dateParam), "yyyy-MM-dd'T'HH:mm:ss"));
	const balanceSheetFlatData = useSelector<any, AccountBalance[]>(state => state.appState.balanceSheet);
	const dataFetching: boolean = useSelector<any, any>(state => state.appState.dataFetching);
	const accounts = useSelector<any, any>(state => state.appState.accounts);
	const bookParams = useSelector<any, any>(state => state.appState.bookParams);
	const [checkTasks, setCheckTasks] = React.useState<BalanceCheckSchedule[]>(null);
	React.useEffect(() => {
		apiClient.getCheckSchedules().then(setCheckTasks);
	}, []);
	if(dataFetching || dateParam == null) return null;
	const balanceSheetData = summarizeData(balanceSheetFlatData, accounts);
	if(checkTasks == null) return null;
	return <>
	<h1>貸借対照表</h1>
		<input type="datetime-local" value={date} onChange={e => setDate(e.target.value)} />
		<Button onClick={() => dispatch({
			type: "balanceSheet",
			query: {
				"date": new Date(date).toISOString(),
			}
		})}>日時変更</Button>
		<h2>資産: <AmountDisplay amount={balanceSheetData.assetsAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<AccountBalanceList checkSchedules={checkTasks} accountBalances={balanceSheetData.assets} dateTo={new Date(date)} dateFrom={dateFns.addMonths(new Date(date), -1)} />
		<h2>負債: <AmountDisplay amount={balanceSheetData.liabilitiesAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<AccountBalanceList checkSchedules={checkTasks} accountBalances={balanceSheetData.liabilities} dateTo={new Date(date)} dateFrom={dateFns.addMonths(new Date(date), -1)} />
		<h2>純資産: <AmountDisplay amount={balanceSheetData.equityAmount} currencyCode={bookParams.bookCurrency} /></h2>
		<AccountBalanceList checkSchedules={checkTasks} accountBalances={balanceSheetData.equities} dateTo={new Date(date)} dateFrom={dateFns.addMonths(new Date(date), -1)} />
	</>;
}
