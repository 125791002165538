import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {AccountTag} from "@src/Models";
import {Overlay} from "@src/Utils";
import {updateAccountTag, deleteAccountTag} from "@src/Actions";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";

function TagEditor(props: {
	id: number;
}) {
	const accountTags = useSelector<any, any>(state => state.appState.accountTags);
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [accountTag, setAccountTag] = React.useState<AccountTag>(null);
	React.useEffect(() => setAccountTag(props.id == null ? {tag: "新規"} : accountTags[props.id]), [showEditor]);
	const dispatch = useDispatch();
	function save() {
		apiClient.saveAccountTag(accountTag).then(accountTag => { dispatch(updateAccountTag(accountTag)); setShowEditor(false); })
	}
	function deleteRecord() {
		apiClient.deleteAccountTag(accountTag.id).then(() => { dispatch(deleteAccountTag(accountTag.id)); setShowEditor(false); })
	}
	if(accountTag == null) return null;
	return <li className={props.id == null ? "new" : ""} onClick={() => setShowEditor(true)}>
		{accountTag.tag}
		{!showEditor || <Overlay onDismiss={() => setShowEditor(false)}>
		<div className="tag-editor">
		<div className="field"><label>タグ</label>
		<input value={accountTag.tag} onChange={e => setAccountTag({...accountTag, tag: e.target.value})} />
		</div>
		<Button variant="primary" onClick={save}>保存</Button>
		<Button variant="primary" onClick={() => setShowEditor(false)}>キャンセル</Button>
		{props.id == null || <Button variant="primary" onClick={deleteRecord}>削除</Button>}
		</div>
		</Overlay>}
	</li>;
}

export function TagsManager() {
	const accountTags = useSelector<any, any>(state => state.appState.accountTags);
	return <>
	<ul className="tags-manager">
	{Object.values(accountTags).map((tag: AccountTag) => <TagEditor key={tag.id} id={tag.id} />)}
	<TagEditor key="new" id={null} />
	</ul>
	</>;
}
