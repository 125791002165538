import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {calendar, newTransaction} from "@src/Actions";
import * as dateFns from "date-fns";
import {Overlay} from "@src/Utils";
import Link from "redux-first-router-link";
function MenuItem(props: {
	icon: string;
	label: string;
	onClick: () => void;
	children?: JSX.Element | JSX.Element[];
}) {
	return <div className="menuItem" onClick={props.onClick}>
	<img src={"/api/ico/" + props.icon + ".svg"} alt={props.label} />
	<span>{props.label}</span>
	{props.children == null || props.children}
	</div>;
}

function OtherMenu() {
	const [visible, setVisible] = React.useState<boolean>(false);
	return <>
		<MenuItem icon="sonota" label="その他" onClick={() => setVisible(true)} />
		{!visible ||
			<Overlay onDismiss={() => setVisible(false)}>
			<ul className="other-menu" onClick={() => setVisible(false)}>
			<li><Link to={{type: "currencies"}}>通貨を管理</Link></li>
			<li><Link to={{type: "accounts"}}>勘定科目を管理</Link></li>
			<li><Link to={{type: "tags"}}>タグを管理</Link></li>
			<li><Link to={{type: "accountTags"}}>勘定科目のタグを設定</Link></li>
			<li><Link to={{type: "linePatterns"}}>明細パターンを管理</Link></li>
			<li><Link to={{type: "transactionPatterns"}}>取引パターンを管理</Link></li>
			</ul>
			</Overlay>
		}
	</>;
}

export function AppMenu() {
	let selectedDate = new Date();
	const dispatch = useDispatch();
	const location = useSelector<any, any>(state => state.location);
	if(location.type == "calendar")
		selectedDate = new Date(Number.parseInt(location.payload.year), Number.parseInt(location.payload.month) - 1, 1)
	if(location.type == "dayDetails") 
		selectedDate = new Date(Number.parseInt(location.payload.year), Number.parseInt(location.payload.month) - 1, Number.parseInt(location.payload.day))
	return <nav>
	<MenuItem icon="home" label="ホーム" onClick={() => dispatch(calendar(selectedDate))} />
	<MenuItem icon="purse" label="入出費" onClick={() => dispatch(
		{
			type: "profitLoss",
			query: {
				from: dateFns.startOfMonth(selectedDate).toISOString(),
				to: dateFns.min([new Date(), dateFns.endOfMonth(selectedDate)]).toISOString()
			}
		}
	)} />
	<MenuItem icon="kiroku" label="記録" onClick={() => dispatch(
		(() => {
			let date = new Date();
			if(location.type == "dayDetails" &&
				(selectedDate.getFullYear() != date.getFullYear() ||
				selectedDate.getMonth() != date.getMonth() ||
				selectedDate.getDate() != date.getDate())) date = selectedDate;
			return newTransaction({
				id: null,
				date: date,
				lines: [],
			});
		})()
	)} />
	<MenuItem icon="zandaka" label="残高" onClick={() => dispatch(
		{
			type: "balanceSheet",
			query: {
				date: new Date().toISOString()
			}
		}
		
	)} />
	<OtherMenu />
	</nav>;
}
