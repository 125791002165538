import * as React from "react";
import {Calculator} from "@src/Calculator";
import {useSelector} from "react-redux";
import {AmountDisplay} from "@src/Utils";

export function AmountField(props: {
	initialValue: number,
	currency: string,
	onChange: (value: number) => void,
}) {
	const [value, setValue] = React.useState<number>(props.initialValue);
	const [calculatorVisible, showCalculator] = React.useState<boolean>(false);
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	function returnValue(value: number) {
		showCalculator(false);
		setValue(value);
		props.onChange && props.onChange(value);
	}
	return <div className="field"><label><img src="/api/ico/kingaku.svg" alt="kingaku" />金額</label>
		<span className="amount-field" onClick={() => showCalculator(true)}><AmountDisplay amount={value} currencyCode={props.currency} /></span>
		{calculatorVisible && <Calculator onReturn={returnValue} initialValue={value} onDismiss={() => showCalculator(false)} />}
	</div>;
}