import * as React from "react";
import {Currency} from "@src/Models";
import {useSelector} from "react-redux";

export function Overlay(props: {
	children: React.ReactElement | React.ReactElement[];
	onDismiss: () => void;
}) {
	function onPopState(e: PopStateEvent) {
		props.onDismiss();
	}
	React.useEffect(() => {
		history.pushState({}, "Overlay");
		window.addEventListener("popstate", onPopState);
		return () => window.removeEventListener("popstate", onPopState);
	}, []);
	return <div className="overlay" onClick={e => {e.stopPropagation(); props.onDismiss();}}><div onClick={e => e.stopPropagation()}>
	{props.children}
	<button className="close-button" onClick={props.onDismiss}>&times;</button>
	</div></div>;
}

export function AmountDisplay(props: {
	amount: number;
	currencyCode: string;
}) {
	const currencies = useSelector<any, any>(state => state.appState.currencies);
	if(props.amount == null) return <>{"**"}</>
	if(props.currencyCode != null) {
		const currency: Currency = currencies[props.currencyCode];
		return <>{new Intl.NumberFormat(undefined, {maximumFractionDigits: currency.decimals}).format(props.amount) + " " + currency.symbol}</>;
	}
	return <>{new Intl.NumberFormat().format(props.amount)}</>;
}