import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import {TransactionLinePattern, AccountTag} from "@src/Models";
import {Overlay} from "@src/Utils";
import {updateLinePattern, deleteLinePattern} from "@src/Actions";
import * as apiClient from "@src/services/ApiClient";
import Button from "react-bootstrap/Button";

function IconSelector(props: {
	currentValue: string;
	onChange: (newValue: string) => void;
}) {
	const icons = useSelector<any, string[]>(state => state.appState.icons);
	const [visible, setVisible] = React.useState<boolean>(false);
	function setIcon(newValue: string) {
		props.onChange(newValue);
		setVisible(false);
	}
	return <>
	{props.currentValue == null ? <span onClick={() => setVisible(true)}>アイコンを選択</span> : <img src={"/api/ico/" + props.currentValue + ".svg"} onClick={() => setVisible(true)} alt={props.currentValue} />}
	{!visible || <Overlay onDismiss={() => setVisible(false)}>
	<ul className="icon-selector">
	{icons.map((icon, index) => <li key={index} onClick={() => setIcon(icon)}><img src={"/api/ico/" + icon + ".svg"} alt={icon} /></li>)}
	</ul>
	</Overlay>}
	</>;
}

function LinePatternEditor(props: {
	id: number;
}) {
	const linePatterns = useSelector<any, any>(state => state.appState.linePatterns);
	const accountTags = useSelector<any, any>(state => state.appState.accountTags);
	const [showEditor, setShowEditor] = React.useState<boolean>(false);
	const [linePattern, setLinePattern] = React.useState<TransactionLinePattern>(null);
	React.useEffect(() => setLinePattern(props.id == null ? {
		description: "新規",
		side: 0,
		tags: []
	} : linePatterns[props.id]), [showEditor]);
	const dispatch = useDispatch();
	function save() {
		apiClient.saveLinePattern(linePattern)
		.then(savedLinePattern => apiClient.setLinePatternTags(savedLinePattern.id, linePattern.tags).then(() => savedLinePattern))
		.then(savedLinePattern => { dispatch(updateLinePattern(savedLinePattern)); setShowEditor(false); })
	}
	function deleteRecord() {
		apiClient.deleteLinePattern(linePattern.id).then(() => { dispatch(deleteLinePattern(linePattern.id)); setShowEditor(false); })
	}
	if(linePattern == null) return null;
	return <li onClick={() => setShowEditor(true)}>
		{linePattern.description}
		{!showEditor || <Overlay onDismiss={() => setShowEditor(false)}>
		<div className="line-pattern-editor">
		<div className="field"><label>名称</label>
		<input value={linePattern.description} onChange={e => setLinePattern({...linePattern, description: e.target.value})} />
		</div>
		<div className="field"><label>サイド</label>
		<select value={linePattern.side} onChange={e => setLinePattern({...linePattern, side: Number.parseInt(e.target.value)})}>
		<option value="0">貸し方</option>
		<option value="1">借り方</option>
		</select>
		</div>
		<div className="field"><label>アイコン</label>
		<IconSelector currentValue={linePattern.icon} onChange={newValue => setLinePattern({...linePattern, icon: newValue})} />
		</div>
		<div className="field"><label>タグ</label>
		<ul className="tags-selector">
		{Object.values(accountTags).map((tag: AccountTag) => <li key={tag.id}><input type="checkbox" checked={linePattern.tags.includes(tag.id)} onChange={e => e.target.checked ? setLinePattern({...linePattern, tags: [...linePattern.tags, tag.id]}) : setLinePattern({...linePattern, tags: linePattern.tags.filter(e => e != tag.id)})} />{tag.tag}</li>)}
		</ul>
		</div>
		<Button variant="primary" onClick={save}>保存</Button>
		<Button variant="primary" onClick={() => setShowEditor(false)}>キャンセル</Button>
		{props.id == null || <Button variant="primary" onClick={deleteRecord}>削除</Button>}
		</div>
		</Overlay>}
	</li>;
}

export function LinePatternsManager() {
	const linePatterns = useSelector<any, any>(state => state.appState.linePatterns);
	return <>
	<ul className="line-patterns-manager">
	{Object.values(linePatterns).map((linePattern: TransactionLinePattern) => <LinePatternEditor key={linePattern.id} id={linePattern.id} />)}
	<LinePatternEditor key="new" id={null} />
	</ul>
	</>;
}
