export enum TransactionLineSide {
	credit, debit
}

export enum AccountType {
	asset, liability, equity, expense, revenue
}

export const debitAccounts: AccountType[] = [AccountType.asset, AccountType.expense];
export const creditAccounts: AccountType[] = [AccountType.equity, AccountType.revenue, AccountType.liability];

export interface User {
	accessToken: string;
}

export interface Transaction {
	id: string;
	date: Date;
	lines: TransactionLine[];
}

export interface TransactionLine {
	side: TransactionLineSide;
	account?: string;
	inputAmount?: number;
	accountAmount?: number;
	bookAmount?: number;
	description: string;
	icon: string;
	purpose?: string;
	tags: number[];
}

export interface Measure {
	value: number;
	unit: string;
}

export interface Account {
	id: string;
	name: string;
	tags: number[];
	currency: string;
	needsPurpose: boolean;
	accountType: AccountType;
}

export interface AccountTag {
	id: number;
	tag: string;
}

export interface Currency {
	code: string;
	decimals: number;
	symbol: string;
	name: string;
}

export interface TransactionPattern {
	id: number;
	description: string;
	lines: number[];
}

export interface TransactionLinePattern {
	id: number;
	side: TransactionLineSide;
	description: string;
	icon: string;
	tags: number[];
}

export interface AccountBalance {
	account: string;
	purpose: string;
	accountAmount: number;
	bookAmount: number;
}

export interface AccountLine {
	transactionId: number;
	lineId: number;
	date: Date;
	title: string;
	accountAmount: number;
	accountStatementId: number;
}

export interface StatementLine {
	accountId: string;
	date: Date;
	description: string;
	amount: number;
}

export interface BalanceCheckSchedule {
	accountId: string;
	date: Date;
	lastCheck: Date;
}