import * as React from "react";
import * as apiClient from "@src/services/ApiClient";
import {User} from '@src/Models';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from "react-redux";
import Link from "redux-first-router-link";

export async function processLogin(dispatch: any, getState: any) {
	const location = getState().location;
	if(location.query != null && location.query.authclient != null && location.query.code == null)
		window.location.href = await apiClient.getAuthUrl(location.query.authclient);
	else if(location.query != null && location.query.authclient != null && location.query.code != null) {
		let user = await apiClient.sendAuthCode({...location.query});
		if(user != null) {
			dispatch({type: "loginUser", user: user});
			dispatch({type: "topPage"});
			dispatch({type: "fetchingComplete"});
		}
	}
}

export function Login() {
	return <>
		<h1>Login</h1>
		<Link to="/login?authclient=google">Google</Link>
	</>
}
